//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VentaServices from "../../../Services/VentaServices";
import VentasHelper  from "../VentasHelper";

const ventaServices = new VentaServices();
export default {
  name: "buscarProductosModal",
  props: ['model', "almacen", "cliente", "busqueda", "tipoVenta", "descuentoVenta", "mostrarImpuestos"],
  components: {
    ValidarCantidadesModal: () => import("../modales/validarCantidadesModal"),
    MostrarStockModal: () => import("../modales/mostrarStockModal"),
  },
  data() {
    return {
      productos: [],
      filtros: {
        busqueda: "",
        marca: "",
        ventasSinStock: -1,
        categoria: "",
        subcategoria: "",
        limit: 500
      },
      marcas: [],
      categorias: [],
      subcategorias: [],
      productoSeleccionado: [],
      columnas: [
        {name: "claveInterna", label: 'Clave', align: 'left', field: "claveInterna", sortable: true, headerClasses: 'w15p'},
        {name: "descripcion", label: 'Descripción', align: 'left', field: "descripcion", sortable: true, headerClasses: 'w35p',classes: "cell-truncate"},
        {name: "marca", label: 'Marca', align: 'left', field: 'marca', sortable: true, headerClasses: 'w15p'},
        {name: "categoria", label: 'Categoría', align: 'left', field: 'categoria', sortable: true, headerClasses: 'w10p'},
        {
          name: "stock",
          label: 'Local',
          align: 'center',
          field: 'stock',
          sortable: true,
          headerClasses: 'w9p',
          format: (val, row) => row.tipoProducto === 104 ? row.stockLocalRelacionado : val
        },
        {
          name: "stockGlobal",
          label: 'Global',
          align: 'center',
          field: 'stockGlobal',
          sortable: true,
          headerClasses: 'w9p',
          format: (val, row) => row.tipoProducto === 104 ? row.stockGlobalRelacionado : val
        },
        {
          name: "precioMostrar",
          label: 'Precio 1',
          align: 'right',
          field: 'precioMostrar',
          sortable: true,
          headerClasses: 'w11p',
          format: val => this.accounting.formatMoney(val)
        },
      ],
      tipoOpciones: [
        {label: "Producto", value: 101},
        {label: "Servicio", value: 102},
        {label: "Alias", value: 104}
      ],
      productoObj: {},
      mostrarStockModal: false,
      mostrarCantidadesModal: false,
      productosModal: false,
      navigationActive: false,
      pagination: {},
    }
  },
  watch: {
    model(value) {
      if (value) {
        this.filtros.busqueda = this.busqueda;
        this.buscarProductos(true);
      }
      else this.onClose();
    }
  },
  methods: {
    onClose() {
      this.model = false;
      this.productosModal = false;
      this.mostrarCantidadesModal = false;
      this.limpiarFiltros();
      this.productoSeleccionado = [];
      this.$emit('on-close');
    },
    onShow() {
      this.loader(true);
      this.$nextTick(async () => {
        await this.listarMarcas();
        await this.obtenerCatSubcategoria();
        this.$refs.first.focus();
      });
      this.loader(false);
    },
    listarMarcas() {
      return ventaServices.listarMarcas()
        .then(marcas => this.marcas = marcas)
        .catch(error => this.alertShow(error, "error"));
    },
    obtenerCatSubcategoria(isCategoria = true) {
      this.loader(true);
      let data = {categoria: isCategoria};
      if (isCategoria)
        data.busqueda = this.filtros.categoria;
      return ventaServices.obtenerCatSubcategoria(data)
        .then(rows => {
          if (isCategoria)
            this.categorias = rows;
          else
            this.subcategorias = rows;
        })
        .catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    limpiarFiltros() {
      this.filtros = {
        marca: "",
        ventasSinStock: -1,
        categoria: "",
        subcategoria: "",
        busqueda: "",
        limit: 500
      };
    },
    obtenerTipo(val) {
      switch (val) {
        case 101:
          return "Producto";
        case 102:
          return "Servicio";
        case 104:
          return "Alias";
      }
    },
    buscarProductos(loader = false) {
      if (loader) this.loader(true);

      let filtros = {
        almacenId: this.almacen,
        clienteId: this.cliente.clienteId,
        ...this.filtros
      };

      return ventaServices.listarProductosVenta(filtros)
        .then(productos => {
          if (productos.length === 1)
            this.obtenerProducto(productos[0]);
          else {
            this.productos = productos;
            this.productosModal = true;
          }
        }).catch(error => {
          this.alertShow(error, "error");
          this.onClose();
        })
        .then(() => {if (loader) this.loader(false)});
    },
    seleccionarProductoRow(evt, row, index) {
      this.obtenerProducto(row);
    },
    seleccionarProducto(details) {
      this.obtenerProducto(details.rows[0]);
    },
    obtenerProducto(row) {
      let codigoPrecio   = this.cliente.precioDefault || row.codigoPrecioDefault,
          precio         = VentasHelper.precioCodigoProducto(row, codigoPrecio, this.mostrarImpuestos),
          sinExistencias = row.facturarSinExistencias;

      if (Number(row.tipoProducto) === 104) {
        let productoStockRelacionado = Number(row.stockLocalRelacionado),
            cantidadEquivalente      = Number(row.cantidadEquivalente);

        if (cantidadEquivalente > productoStockRelacionado && sinExistencias === 0) {
          if (this.model && !this.productosModal) this.onClose();
          return this.alertShow(`La cantidad del producto alias (${cantidadEquivalente}) es mayor al stock del producto base (${productoStockRelacionado}).`, "error");
        }

      }
      let totalConIeps = precio + (row.ieps * precio);
      let totalConIva = precio + (row.iva * totalConIeps);
      let producto     = {
        ...row,
        cantidad: 1,
        cantidadX: 0,
        cantidadY: 0,
        cantidadZ: 0,
        cantidadW: 0,
        precio: precio,
        precioConDesc: precio,
        precioLista: codigoPrecio,
        descuentoPorcentaje: this.descuentoVenta,
        isLargoVisible: (Number(row.tipoCantidad) === 200 || Number(row.tipoCantidad) === 300 || Number(row.tipoCantidad) === 400),
        isAnchoVisible: (Number(row.tipoCantidad) === 300 || Number(row.tipoCantidad) === 400),
        isProfundidadVisible: Number(row.tipoCantidad) === 400,
        importeUnitario: precio,
        importeTotal: precio,
        importeIeps: (row.ieps * precio),
        importeTotalConIeps: totalConIeps,
        importeIva: totalConIva,
        importeTotalConIva: totalConIva,
        importeUnitarioImp: totalConIva,
        preciosConImpuestosAgregados: false,
      };

      if (this.tipoVenta !== "Cotizacion") {
        if (sinExistencias === 0 && producto.stock <= 0 && producto.facturarSinExistencias === 0) {
          this.productoObj = producto;
          return this.mostrarStockModal = true;
        } else if (Number(producto.tipoCantidad) === 200 || Number(producto.tipoCantidad) === 300 || Number(producto.tipoCantidad) === 400) {
          this.productoObj = producto;
          return this.mostrarCantidadesModal = true;
        } else {
          this.productosModal = false;
          this.aplicarCantidadesProducto(producto);
        }
      } else {
        this.productosModal = false;
        this.aplicarCantidadesProducto(producto);
      }
    },
    aplicarCantidadesProducto(producto) {
      this.onClose();
      this.$emit("get-producto", producto);
    },
    cerrarStockModal() {
      this.mostrarStockModal = false;
      if (!this.productosModal) this.onClose();
    },
    activateNavigation() {this.navigationActive = true;},
    deactivateNavigation() {this.navigationActive = false;},
    onKey(evt) {
      if (this.navigationActive !== true || [38, 40, 13].indexOf(evt.keyCode) === -1 || this.$refs.myTable === void 0)
        return;

      if (evt.keyCode === 13) return this.seleccionarProductoRow(null, this.productoSeleccionado[0], null);

      evt.preventDefault();

      let {computedRowsNumber, computedRows} = this.$refs.myTable;

      if (computedRows.length === 0) return;

      let currentIndex = this.productoSeleccionado.length > 0 ? computedRows.indexOf(this.productoSeleccionado[0]) : -1;
      let currentPage = this.pagination.page;
      let rowsPerPage = this.pagination.rowsPerPage === 0 ? computedRowsNumber : this.pagination.rowsPerPage;
      let lastIndex = computedRows.length - 1;
      let lastPage = Math.ceil(computedRowsNumber / rowsPerPage);

      let index = currentIndex;
      let page = currentPage;

      switch (evt.keyCode) {
        case 38: // ArrowUp
          if (currentIndex <= 0) {
            page = currentPage <= 1 ? lastPage : currentPage - 1;
            index = rowsPerPage - 1;
            this.$refs.myTable.scrollTo(index);
          } else {
            index = currentIndex - 1;
            this.$refs.myTable.scrollTo(index - 9);
          }
          break;
        case 40: // ArrowDown
          if (currentIndex >= lastIndex) {
            page = currentPage >= lastPage ? 1 : currentPage + 1;
            index = 0;
            this.$refs.myTable.scrollTo(index);
          } else {
            index = currentIndex + 1;
            if (currentIndex > 7) this.$refs.myTable.scrollTo(currentIndex - 8);
          }
          break;
      }

      if (page !== this.pagination.page) {
        this.pagination = {...this.pagination, page};

        this.$nextTick(() => {
          let {computedRows} = this.$refs.myTable;
          this.productoSeleccionado = [computedRows[Math.min(index, computedRows.length - 1)]];
        })
      } else {
        this.productoSeleccionado = [computedRows[index]];
      }
    },
  }
}
